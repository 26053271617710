import React, { useMemo } from 'react';
import styled from 'styled-components';
import { formatUnits } from '@ethersproject/units';
import {
  ERC20Interface,
  useCalls,
  useEthers,
  useTokenList,
  useEtherBalance,
  useTokenBalance,
  useChainState,
  useGasPrice,
} from '@usedapp/core';
import { toHttpPath } from '@/helpers/utilities';
import { Contract } from '@ethersproject/contracts';
import { find } from 'lodash';
import { SUPPORTED_CHAINS } from '@/constants';

import { Stack, Text, Flex, Box, Image } from '@chakra-ui/react';

const UNISWAP_DEFAULT_TOKEN_LIST_URI =
  'https://gateway.ipfs.io/ipns/tokens.uniswap.org';

function useTokensBalance(tokenList?: any[], account?: string | null) {
  return useCalls(
    tokenList && account
      ? tokenList.map((token: any) => ({
          contract: new Contract(token.address, ERC20Interface),
          method: 'balanceOf',
          args: [account],
        }))
      : []
  );
}

const TokenItem = ({ token, balance }) => {
  const formatBalanceValue =
    balance && !balance.error
      ? formatUnits(balance.value[0], token.decimals)
      : 0;
  return (
    <Flex alignItems="center">
      <Box mr="15px">
        {token.logoURI && (
          <Image
            fallbackSrc="https://via.placeholder.com/150"
            src={toHttpPath(token.logoURI)}
            w="30px"
            h="30px"
            borderRadius="full"
            boxSize={'30px'}
            alt={`${token.symbol} logo`}
          />
        )}
      </Box>
      <Box>
        <Text fontSize="20px" fontWeight={'600'}>
          {formatBalanceValue} {token.symbol}
        </Text>
        <Text color="outline.400">{token.name}</Text>
      </Box>
    </Flex>
  );
};

const getChainDetails = chainId =>
  find(SUPPORTED_CHAINS, ['chain_id', chainId]);

function TokenList({ account, chainId }) {
  const { name, logoURI, tokens } =
    useTokenList(UNISWAP_DEFAULT_TOKEN_LIST_URI, chainId) || {};
  const balances = useTokensBalance(tokens, account);

  const nativeCurrencyBalance = useEtherBalance(account, {
    chainId,
  });

  // TODO add custom tokens per chain address
  // const stkBalance = useTokenBalance(
  //   '0xb625bD4B866DA272f8D3d0827E101dFcDd198667',
  //   account,
  //   { chainId }
  // );
  // const fethBalance = useTokenBalance(
  //   '0x18e1A50ea5626D9984ad014de3C3989C7625aD6f',
  //   account,
  //   { chainId }
  // );

  const chain = useMemo(() => getChainDetails(chainId), [chainId]);

  return (
    <Stack>
      <TokenItem
        balance={{
          error: !nativeCurrencyBalance,
          value: nativeCurrencyBalance ? [nativeCurrencyBalance] : [],
        }}
        token={{
          symbol: chain.native_currency.symbol,
          name: chain.native_currency.name,
          logoURI: 'https://via.placeholder.com/150',
          decimals: chain.native_currency.decimals,
        }}
      />
      {/* <TokenItem
        token={{
          balance: { value: [stkBalance] },
          symbol: 'STK',
          name: 'Stonks',
          logoURI: 'https://via.placeholder.com/150',
        }}
      />
      <TokenItem
        token={{
          balance: { value: [fethBalance] },
          symbol: 'FETH',
          name: 'Flex Eth',
          logoURI: 'https://via.placeholder.com/150',
        }}
      /> */}

      {tokens &&
        tokens.map((token, idx) => {
          const balance = balances[idx];
          return (
            <TokenItem key={token.address} token={token} balance={balance} />
          );
        })}
    </Stack>
  );
}

export default TokenList;
