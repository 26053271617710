import {
  Avatar,
  Box,
  Text,
  Container,
  Flex,
  Link,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';

import { get } from 'lodash';
import { Link as ReachLink } from '@reach/router';
import styled, { css } from 'styled-components';
import { WalletAddress } from '@flex-connect/ui';

import StatusIndicator from '@/components/atoms/Status';

const truncate = input =>
  input.length > 10 ? `${input.substring(0, 10)}...` : input;

const FooterBanner = ({ peerMeta, connected, killSession }) => {
  if (!connected) return null;
  return (
    <Flex
      bg="green"
      direction={'row'}
      width={'full'}
      // py={'20px'}
      px={'15px'}
      position={'absolute'}
      bottom={0}
      height="40px"
      alignItems={'center'}
      justifyContent={'space-between'}
      pb={'env(safe-area-inset-bottom, 20px)'}
    >
      <Box flex="1">
        <Flex alignItems={'center'} whiteSpace="nowrap" overflow="hidden">
          <StatusIndicator />
          <Text as="div" pl="10px">
            Connected to{' '}
            <Text as="span" fontWeight={700}>
              {truncate(peerMeta.name)}
            </Text>
          </Text>
        </Flex>
      </Box>
      <Button variant="link" onClick={killSession}>
        Disconnect
      </Button>
    </Flex>
  );
};

export default FooterBanner;
