// @ts-nocheck
import * as React from 'react';
import { useKeycloak } from "@react-keycloak/web";
import { useAuth } from "@/context/UserContext"
import { useFlexPopup } from '@/hooks/useFlexPopup'
import { useApp } from '@/context/AppContext';

import { Load } from "@flex-connect/ui"


import HeaderBanner from '@/components/modules/HeaderBanner'
import ProfileBanner from '@/components/modules/ProfileBanner'
import Main from '@/layout/Main'

import AuthenticationScreen from './AuthenticationScreen'

interface AuthenticationProps {
  children: React.ReactNode
}

const Authentication = ({ children }: AuthenticationProps) => {
  // @ts-ignore
  const { user, isSuccess } = useAuth();
  const [state, setState] = useApp();
  const { keycloak } = useKeycloak();
  // bind listerner
  useFlexPopup();

  return (
    <Main>


      {!keycloak.authenticated &&
        <AuthenticationScreen keycloak={keycloak} />}

      {keycloak.authenticated &&
        <Load isLoading={!isSuccess} loadingText="Signing in">
          {children}
        </Load>}
    </Main>
  )
};

export default Authentication;
