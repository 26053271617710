import styled, { css } from 'styled-components';

const Icon = styled.div`
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 24px;
  height: 24px;
  display: block;
`;

export default Icon;
