// @ts-nocheck
import * as React from 'react';
import { Button, Text, Box, Stack, Flex } from '@chakra-ui/react'
import { ReactComponent as FLEX } from "src/assets/icons/FLEX.svg"
// 
import eye from "src/assets/icons/Eye.svg"
import key from "src/assets/icons/Key.svg"
import wallet from "src/assets/icons/Wallet.svg"

import Icon from '@/components/atoms/Icon'

const featuresIcons = [eye,
  key,
  wallet]
const features = ["Use to store your NFTs and crypto - a fully functional token wallet", "View balance, activity and sign transactions from any device", "Only you can access your wallet (we don’t store your private key)"]

const AuthenticationScreen = ({ keycloak }) => {
  return <Flex direction={"column"} mx="15px">
    <Box pt="90px" textAlign={"center"}>
      <Box as={FLEX} mx="auto" />
      <Text mt="15px" mb="60px" fontWeight={"700"} fontSize="22px">Sign in to token wallet</Text>
    </Box>

    <Stack mb="90px" mx="15px">
      {features.map((text, i) => <Flex key={i} fontSize="15px">
        <Box mr="15px" as={Icon} icon={featuresIcons[i]} />
        {text}</Flex>)}
    </Stack>

    <Button variant="" bg="green" size="lg" onClick={() => keycloak.login()}>Sign in</Button>
    <Text mt="15px" textAlign={"center"} color="outline.400">Use your Flex credentials to access wallet</Text>
  </Flex>
};

export default AuthenticationScreen;
