// @ts-nocheck
import styled from 'styled-components';
import Button from '@/components/Button';
import Input from '@/components/Input';
import RequestButton from '@/components/RequestButton';

const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
`;

S.VersionNumber = styled.div`
  position: absolute;
  font-size: 12px;
  bottom: 6%;
  right: 0;
  opacity: 0.3;
  transform: rotate(-90deg);
`;

S.Content = styled.div`
  width: 100%;
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

S.Logo = styled.div`
  padding: 10px 0;
  display: flex;
  max-height: 100px;
  & img {
    width: 100%;
  }
`;

S.Actions = styled.div`
  margin: 0;
  margin-top: 20px;

  display: flex;
  justify-content: space-around;
  & > * {
    margin: 0 5px;
  }
`;

S.ActionsColumn = styled(S.Actions as any)`
  flex-direction: row;
  align-items: center;

  margin: 24px 0 6px;

  & > p {
    font-weight: 600;
  }
`;

S.Button = styled(Button)`
  width: 50%;
  height: 40px;
`;

S.Input = styled(Input)`
  width: 80%;
  margin-top: 30px;
  font-size: 16ox;
  height: 40px;
  color: '#000';
`;

S.ConnectedPeer = styled.div`
  display: flex;
  align-items: center;
  & img {
    width: 40px;
    height: 40px;
  }
  & > div {
    margin-left: 10px;
  }
`;

S.RequestButton = styled(RequestButton)`
  margin-bottom: 10px;
`;

export default S;
