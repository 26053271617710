// @ts-nocheck
import * as React from "react";

import Wallet from './Wallet'
import { useApp } from '@/context/AppContext';
import { useAuth } from '@/context/UserContext';

const WalletContainer = () => {
  const [state, setState] = useApp();
  const { user, isSuccess } = useAuth();
  return <Wallet state={state} user={user} setState={setState} />
}

export default WalletContainer