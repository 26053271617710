import { useState } from "react";
import { Text, Tooltip } from "@chakra-ui/react";
import { shortenAddress } from "@usedapp/core";

import Copy from "copy-to-clipboard";

export default ({ address }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    Copy(address);
    setCopied(true);
  };
  return (
    <Tooltip
      hasArrow
      label={copied ? "Copied" : "Copy to clipboard"}
      bg="gray.400"
      color="black"
      onClose={() => setCopied(false)}
      closeDelay={600}
    >
      <Text
        as="button"
        onClick={handleClick}
        color="gray.400"
        fontSize={"14px"}
      >
        {shortenAddress(address)}
      </Text>
    </Tooltip>
  );
};
