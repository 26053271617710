// @ts-nocheck
import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from "react";
import { useQuery } from "react-query";

import { useKeycloak } from "@react-keycloak/web";

import { getAppControllers } from "@/controllers";
// create context
const UserContext = createContext(null);

interface UserContextProps {
  children: React.ReactNode;
}

const UserContextProvider = ({ children }: UserContextProps) => {
  // the value that will be given to the context
  const { keycloak } = useKeycloak();
  const [user, setUser] = useState();
  // const [isLoading, setIsLoading] = useState(true);
  const sdk = getAppControllers().sdk;
  const {
    isLoading,
    error,
    isLoadingError,
    data: userData,
    isFetching,
    isSuccess,
    refetch: fetchUser,
  } = useQuery("profiles::me", () => sdk.api.getProfile(), { enabled: false });

  useEffect(() => {
    if (userData) setUser(userData);
  }, [userData]);

  // sign out the user, memoized
  const signOut = useCallback(() => {
    setUser(undefined);
    flexSignOut();
  }, []);

  // fetch a user from a fake backend API
  useEffect(() => {
    if (keycloak.authenticated) {
      fetchUser();
    }
  }, [keycloak.authenticated]);

  // memoize the full context value
  const contextValue = useMemo(
    () => ({
      auth: {
        setUser,
      },
      error,
      isFetching,
      isLoading,
      isLoadingError,
      isSuccess,
      refetchUserProfile: fetchUser,
      signOut,
      user,
    }),
    [user, isSuccess, signOut],
  );



  return (
    // the Provider gives access to the context to its children
    // @ts-ignore
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

const useAuth = () => useContext(UserContext);

export { UserContext, UserContextProvider, useAuth };
