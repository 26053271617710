import { WalletController, getWalletController } from './wallet';
import { StoreController, getStoreController } from './store';
import { getFlexController } from './flex';
import { getSignerController } from './signer';

interface IAppControllers {
  store: StoreController;
  wallet: WalletController;
  sdk: any;
  signer: any;
}

let controllers: IAppControllers | undefined;

export function setupAppControllers(): IAppControllers {
  const sdk = getFlexController();
  const wallet = getWalletController();
  const store = getStoreController();
  const signer = getSignerController();

  controllers = { store, wallet, sdk, signer };
  return controllers;
}

export function getAppControllers(): IAppControllers {
  let _controllers = controllers;
  if (!_controllers) {
    _controllers = setupAppControllers();
  }
  return _controllers;
}
