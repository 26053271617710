// @ts-nocheck
import * as ethers from 'ethers';
import { signTypedData } from '@metamask/eth-sig-util';
import { getChainData } from '../helpers/utilities';
import { setLocal, getLocal } from '../helpers/local';
import { getAppControllers } from './index';

import {
  ENTROPY_KEY,
  MNEMONIC_KEY,
  DEFAULT_ACTIVE_INDEX,
  DEFAULT_CHAIN_ID,
} from '../constants/default';
import { getAppConfig } from '../config';

export class WalletController {
  public path: string;
  public entropy: string;
  public mnemonic: string;
  public wallet: ethers.Wallet | null;

  public activeIndex: number = DEFAULT_ACTIVE_INDEX;
  public activeChainId: number = DEFAULT_CHAIN_ID;

  constructor() {
    this.path = this.getPath();
    this.entropy = this.getEntropy();
    this.mnemonic = this.getMnemonic();
    this.wallet = null;
  }

  get provider(): ethers.providers.Provider {
    return this.wallet.provider;
  }

  public isActive() {
    if (this.wallet) {
      return this.wallet;
    }
    return null;
  }

  public getIndex() {
    return this.activeIndex;
  }

  public getWallet(index?: number, chainId?: number): ethers.Wallet {
    if (
      !this.wallet ||
      this.activeIndex === index ||
      this.activeChainId === chainId
    ) {
      return this.init(index, chainId);
    }
    return this.wallet;
  }

  public async getAccounts() {
    if (!this.wallet) {
      throw Error('The wallet has not been init');
    }
    const accounts = [];
    let wallet = null;
    wallet = this.wallet;
    accounts.push(wallet.address);
    return accounts;
  }

  public getData(key: string): string {
    let value = getLocal(key);
    if (!value) {
      switch (key) {
        case ENTROPY_KEY:
          value = this.generateEntropy();
          break;
        case MNEMONIC_KEY:
          value = this.generateMnemonic();
          break;
        default:
          throw new Error(`Unknown data key: ${key}`);
      }
      setLocal(key, value);
    }
    return value;
  }

  public getPath(index: number = this.activeIndex) {
    this.path = `${getAppConfig().derivationPath}/${index}`;
    return this.path;
  }

  public generateEntropy(): string {
    this.entropy = ethers.utils.hexlify(ethers.utils.randomBytes(16));
    return this.entropy;
  }

  public generateMnemonic() {
    this.mnemonic = ethers.utils.entropyToMnemonic(this.getEntropy());
    return this.mnemonic;
  }

  public async generateWallet(index: number) {
    const signer = await this.getPrivateKey();
    this.wallet = new ethers.Wallet(signer.privateKey);
    return this.wallet;
  }

  public _generateWallet(index: number) {
    this.wallet = ethers.Wallet.fromMnemonic(
      this.getMnemonic(),
      this.getPath(index)
    );
    return this.wallet;
  }

  public getEntropy(): string {
    return this.getData(ENTROPY_KEY);
  }

  public getMnemonic(): string {
    return this.getData(MNEMONIC_KEY);
  }

  public getPrivateKey(): string {
    const { signer } = getAppControllers();

    return signer.init();
  }

  public init(
    index = DEFAULT_ACTIVE_INDEX,
    chainId = DEFAULT_CHAIN_ID
  ): ethers.Wallet {
    return this.update(index, chainId);
  }

  public async update(index: number, chainId: number): ethers.Wallet {
    const firstUpdate = typeof this.wallet === 'undefined';
    this.activeIndex = index;
    this.activeChainId = chainId;
    const rpcUrl = getChainData(chainId).rpc_url;
    const wallet = await this.generateWallet(index);

    const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
    this.wallet = wallet.connect(provider);
    if (!firstUpdate) {
      // update another controller if necessary here
    }
    return this.wallet;
  }

  public async populateTransaction(transaction: any) {
    let tx = Object.assign({}, transaction);
    if (this.wallet) {
      if (tx.gas) {
        tx.gasLimit = tx.gas;
        delete tx.gas;
      }
      if (tx.from) {
        tx.from = ethers.utils.getAddress(tx.from);
      }

      try {
        tx = await this.wallet.populateTransaction(tx);
        console.log('tx', tx);
        tx.gasLimit = ethers.BigNumber.from(tx.gasLimit).toHexString();
        tx.gasPrice =
          tx.gasPrice && ethers.BigNumber.from(tx.gasPrice).toHexString();
        tx.nonce = ethers.BigNumber.from(tx.nonce).toHexString();
      } catch (err) {
        console.error('Error populating transaction', tx, err);
      }
    }

    return tx;
  }

  public async sendTransaction(transaction: any) {
    if (this.wallet) {
      if (
        transaction.from &&
        transaction.from.toLowerCase() !== this.wallet.address.toLowerCase()
      ) {
        console.error("Transaction request From doesn't match active account");
      }

      if (transaction.from) {
        delete transaction.from;
      }

      // ethers.js expects gasLimit instead
      if ('gas' in transaction) {
        transaction.gasLimit = transaction.gas;
        delete transaction.gas;
      }

      const result = await this.wallet.sendTransaction(transaction);
      return result.hash;
    } else {
      console.error('No Active Account');
    }
    return null;
  }

  public async signTransaction(data: any) {
    if (this.wallet) {
      if (data && data.from) {
        delete data.from;
      }
      data.gasLimit = data.gas;
      delete data.gas;
      const result = await this.wallet.signTransaction(data);
      return result;
    } else {
      console.error('No Active Account');
    }
    return null;
  }

  public async signMessage(data: any) {
    if (this.wallet) {
      const signingKey = new ethers.utils.SigningKey(this.wallet.privateKey);
      const sigParams = await signingKey.signDigest(
        ethers.utils.arrayify(data)
      );
      const result = await ethers.utils.joinSignature(sigParams);
      return result;
    } else {
      console.error('No Active Account');
    }
    return null;
  }

  public async signPersonalMessage(message: any) {
    if (this.wallet) {
      const result = await this.wallet.signMessage(
        ethers.utils.isHexString(message)
          ? ethers.utils.arrayify(message)
          : message
      );
      return result;
    } else {
      console.error('No Active Account');
    }
    return null;
  }

  public async signTypedData(data: any) {
    if (this.wallet) {
      const result = signTypedData({
        data: JSON.parse(data),
        privateKey: Buffer.from(this.wallet.privateKey.slice(2), 'hex'),
        version: 'V4',
      });
      return result;
    } else {
      console.error('No Active Account');
    }
    return null;
  }
}

let walletController;
export function getWalletController() {
  if (!walletController) {
    walletController = new WalletController();
  }
  return walletController;
}
