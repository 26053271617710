// @ts-nocheck
import { getAppConfig } from "@/config";
import WalletConnect from "@walletconnect/client";

import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from "react";

import { getAppControllers } from "@/controllers";

export interface IAppState {
  loading: boolean;
  scanner: boolean;
  connector: WalletConnect | null;
  uri: string;
  peerMeta: {
    description: string;
    url: string;
    icons: string[];
    name: string;
    ssl: boolean;
  };
  connected: boolean;
  chainId: number;
  accounts: string[];
  activeIndex: number;
  address: string;
  requests: any[];
  results: any[];
  payload: any;
}


export const INITIAL_STATE: IAppState = {
  loading: false,
  scanner: false,
  connector: null,
  uri: "",
  peerMeta: {
    description: "",
    url: "",
    icons: [],
    name: "",
    ssl: false,
  },
  connected: false,
  chainId: getAppConfig().chainId || DEFAULT_CHAIN_ID,
  accounts: [],
  address: "",
  activeIndex: 0,
  requests: [],
  results: [],
  payload: null,
};


// // create context
const AppContext = createContext(INITIAL_STATE);

// interface UserContextProps {
//   children: React.ReactNode;
// }

const AppContextProvider = ({ children }) => {
  const [state, _setState] = useState(INITIAL_STATE);

  // fetch a user from a fake backend API
  useEffect(() => {
    console.log("Updated -- ", state)
  }, [state]);

  const setState = (newState) => {
    console.log("newState", newState)
    _setState((prevState) => ({
      ...prevState,
      ...newState
    }))
  }

  // memoize the full context value
  const contextValue = useMemo(
    () => ([
      state,
      setState,
    ]),
    [state],
  );




  return (
    // the Provider gives access to the context to its children
    // @ts-ignore
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

const useApp = () => useContext(AppContext);

export { AppContext, AppContextProvider, useApp };
