// @ts-nocheck
import * as React from "react";
import styled from "styled-components";

import WalletConnect from "@walletconnect/client";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Input from "@/components/Input";
import Header from "@/components/Header";
import Column from "@/components/Column";
import PeerMeta from "@/components/PeerMeta";
import RequestDisplay from "@/components/RequestDisplay";
import RequestButton from "@/components/RequestButton";
import AccountDetails from "@/components/AccountDetails";
import QRCodeScanner, {
  IQRCodeValidateResponse,
} from "@/components/QRCodeScanner";
import { DEFAULT_CHAIN_ID, DEFAULT_ACTIVE_INDEX } from "@/constants/default";
import { getCachedSession } from "@/helpers/utilities";
import { getAppControllers } from "@/controllers";
import { getAppConfig } from "@/config";
import S from './styles'

import { IAppState, INITIAL_STATE } from "@/context/AppContext"

import HeaderBanner from '@/components/modules/HeaderBanner'
import ProfileBanner from '@/components/modules/ProfileBanner'
import Dropdown from "@/components/Dropdown";
import ConnectionBanner from '@/components/modules/ConnectionBanner'
import AssetsActivityTabs from '@/components/modules/AssetsActivityTabs'
import ActionModal from '@/components/modules/ActionModal'
import SiteMetaBanner from '@/components/modules/SiteMetaBanner'
import ActivityRequest from '@/components/modules/ActivityRequest'

import TokenList from '@/components/modules/TokenList'


import { Button as CButton, ButtonGroup, Heading, Stack, Text, Flex } from '@chakra-ui/react'



// see example https://github.com/WalletConnect/walletconnect-test-wallet
class App extends React.Component<{}> {
  public state: IAppState;

  constructor(props: any) {
    super(props);

  }

  public componentDidMount() {
    this.init();
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.state.connector !== this.props.state.connector) {
      console.log("connector updated")
      // Due to context state and async setState the listeners has to bind
      // to the correct connector after it is set in state
      this.subscribeToEvents();
    }
  }

  public init = async () => {
    let { activeIndex, chainId, } = this.props.state;

    const session = getCachedSession();



    await getAppControllers().signer.init();

    // FlexConnect Init
    if (!session) {
      await getAppControllers().wallet.init(activeIndex, chainId);
      const accounts = await getAppControllers().wallet.getAccounts();

      await this.props.setState({
        address: accounts[activeIndex],
        accounts,
        chainId,
        activeIndex,
      });


    } else {
      const connector = new WalletConnect({ session });

      const { connected, accounts, peerMeta } = connector;

      const address = accounts[0];

      activeIndex = accounts.indexOf(address);
      chainId = connector.chainId;

      await getAppControllers().wallet.init(activeIndex, chainId);

      await this.props.setState({
        connected,
        connector,
        address,
        activeIndex,
        accounts,
        chainId,
        peerMeta,
      });

      // Moved to useEffect
      // this.subscribeToEvents();
    }

    await getAppConfig().events.init(this.props.state, this.bindedSetState);

    // Check WC url in query string or set in local
    // detect sessionId in URL 
    if (!session) {
      await this.onFlexSession()
    }
  };

  public bindedSetState = (newState: Partial<IAppState>) =>
    this.props.setState(newState);

  public initWalletConnect = async (sessionId: string) => {

    await this.props.setState({ loading: true });

    const { uri } = this.props.state;

    try {
      console.log("connector", uri || sessionId)
      const connector = new WalletConnect({ uri: uri || sessionId });
      console.log("connector", connector)
      if (!connector.connected) {
        await connector.createSession();
      }

      await this.props.setState({
        loading: false,
        connector,
        uri: connector.uri,
      });
      // Move to useEffect
      // this.subscribeToEvents();
    } catch (error) {
      this.props.setState({ loading: false });

      throw error;
    }
  };

  public approveSession = () => {
    console.log("ACTION", "approveSession");
    const { connector, chainId, address } = this.props.state;
    if (connector) {
      connector.approveSession({ chainId, accounts: [address] });
    }
    // this.props.setState({ connector });
  };

  public rejectSession = () => {
    console.log("ACTION", "rejectSession");
    const { connector } = this.props.state;
    if (connector) {
      connector.rejectSession();
    }
    this.props.setState({ connector });
  };

  public killSession = () => {
    console.log("ACTION", "killSession");
    const { connector } = this.props.state;
    if (connector) {
      connector.killSession();
    }
    this.resetApp();
  };

  public resetApp = async () => {
    await this.props.setState({ ...INITIAL_STATE });
    window.localStorage.removeItem("flexconnect:wc")
    this.init();
  };

  public subscribeToEvents = () => {
    console.log("ACTION", "subscribeToEvents");
    const { connector } = this.props.state;

    if (connector) {
      connector.on("session_request", (error, payload) => {
        console.log("EVENT", "session_request");

        if (error) {
          throw error;
        }
        console.log("SESSION_REQUEST", payload.params);
        const { peerMeta } = payload.params[0];
        this.props.setState({ peerMeta });
      });

      connector.on("session_update", error => {
        console.log("EVENT", "session_update");

        if (error) {
          throw error;
        }
      });

      connector.on("call_request", async (error, payload) => {
        // tslint:disable-next-line
        console.log("EVENT", "call_request", "method", payload.method);
        console.log("EVENT", "call_request", "params", payload.params);

        if (error) {
          throw error;
        }

        await getAppConfig().rpcEngine.router(
          payload,
          this.props.state,
          this.bindedSetState,
        );
      });

      connector.on("connect", (error, payload) => {
        console.log("EVENT", "connect");

        if (error) {
          throw error;
        }

        this.props.setState({ connected: true });
      });

      connector.on("disconnect", (error, payload) => {
        console.log("EVENT", "disconnect");

        if (error) {
          throw error;
        }

        this.resetApp();
      });

      if (connector.connected) {
        const { chainId, accounts } = connector;
        const index = 0;
        const address = accounts[index];
        getAppControllers().wallet.update(index, chainId);
        this.props.setState({
          connected: true,
          address,
          chainId,
        });
      }

      this.props.setState({ connector });
    }
  };

  public updateSession = async (sessionParams: {
    chainId?: number;
    activeIndex?: number;
  }) => {
    const { connector, chainId, accounts, activeIndex } = this.props.state;
    const newChainId = sessionParams.chainId || chainId;
    const newActiveIndex = sessionParams.activeIndex || activeIndex;
    const address = accounts[newActiveIndex];
    if (connector) {
      connector.updateSession({
        chainId: newChainId,
        accounts: [address],
      });
    }
    await this.props.setState({
      connector,
      address,
      accounts,
      activeIndex: newActiveIndex,
      chainId: newChainId,
    });
    await getAppControllers().wallet.update(newActiveIndex, newChainId);
    await getAppConfig().events.update(this.props.state, this.bindedSetState);
  };

  public updateChain = async (chainId: number | string) => {
    await this.updateSession({ chainId: Number(chainId) });
  };

  public updateAddress = async (activeIndex: number) => {
    await this.updateSession({ activeIndex });
  };

  public toggleScanner = () => {
    console.log("ACTION", "toggleScanner");
    this.props.setState({ scanner: !this.props.state.scanner });
  };

  public onQRCodeValidate = (data: string): IQRCodeValidateResponse => {
    const res: IQRCodeValidateResponse = {
      error: null,
      result: null,
    };
    try {
      res.result = data;
    } catch (error) {
      res.error = error;
    }

    return res;
  };

  public onQRCodeScan = async (data: any) => {
    const uri = typeof data === "string" ? data : "";
    if (uri) {
      await this.props.setState({ uri, scanner: false });
      await this.initWalletConnect();
      this.toggleScanner();
    }
  };

  public onFlexSession = async () => {
    const flexwc = window.localStorage.getItem("flexconnect:wc")
    if (flexwc) {
      await this.props.setState({ uri: flexwc, scanner: false });
      await this.initWalletConnect(flexwc);
    }
  }

  public onURIPaste = async (e: any) => {
    const data = e.target.value;
    const uri = typeof data === "string" ? data : "";
    if (uri) {
      await this.props.setState({ uri, scanner: false });
      await this.initWalletConnect();
    }
  };

  public onQRCodeError = (error: Error) => {
    throw error;
  };

  public onQRCodeClose = () => this.toggleScanner();

  public openRequest = async (request: any) => {
    const payload = Object.assign({}, request);

    const params = payload.params[0];
    if (request.method === "eth_sendTransaction") {
      payload.params[0] = await getAppControllers().wallet.populateTransaction(
        params,
      );
    }

    this.props.setState({
      payload,
    });
  };

  public closeRequest = async (removeRequest = false) => {
    const { requests, payload } = this.props.state;


    const newState = {
      payload: null,
    }

    if (removeRequest) {
      const filteredRequests = requests.filter(
        request => request.id !== payload.id,
      );
      newState.requests = filteredRequests;
    }

    await this.props.setState(newState);
  };

  public approveRequest = async () => {

    const { connector, payload } = this.props.state;

    try {
      await getAppConfig().rpcEngine.signer(
        payload,
        this.props.state,
        this.bindedSetState,
      );
    } catch (error) {
      console.error(error);
      if (connector) {
        connector.rejectRequest({
          id: payload.id,
          error: { message: "Failed or Rejected Request" },
        });
      }
    }

    this.closeRequest(true);
    await this.props.setState({ connector });
  };

  public rejectRequest = async () => {
    const { connector, payload } = this.props.state;
    if (connector) {
      connector.rejectRequest({
        id: payload.id,
        error: { message: "Failed or Rejected Request" },
      });
    }
    await this.closeRequest(true);
    await this.props.setState({ connector });
  };

  public render() {
    const {
      peerMeta,
      scanner,
      connected,
      activeIndex,
      accounts,
      address,
      chainId,
      requests,
      payload,

    } = this.props.state;
    const user = this.props.user;

    return (
      <React.Fragment>
        <S.Container>
          {/* Header */}
          <HeaderBanner connected={connected}
            address={address}
            chainId={chainId}
            connected={connected}
            toggleScanner={this.toggleScanner}
          >
            <Dropdown
              connected={connected}
              selected={chainId}
              options={getAppConfig().chains}
              displayKey={"name"}
              targetKey={"chain_id"}
              onChange={this.updateChain}
            />
          </HeaderBanner>

          {/* Profile */}
          <ProfileBanner
            username={user?.username}
            walletAddress={address}
          />

          {/* Tabs */}
          {address && <AssetsActivityTabs
            assets={<TokenList account={address} chainId={chainId} />}
            pendingActivity={requests.length > 0}
            activity={<>
              <Heading size='md' mb="20px">{requests.length > 0 ? "Pending" : "No pending requests"}</Heading>
              {connected && !payload && (
                <Stack spacing='20px'>
                  {requests.length && (
                    requests.map(request => (
                      <ActivityRequest
                        peerMeta={peerMeta}
                        key={request.id}
                        request={request}
                        openRequest={this.openRequest}
                      />
                    ))
                  )}
                </Stack>
              )}
            </>} />}


          {(!connected && peerMeta && peerMeta.name) && (
            <ActionModal title="Connect a site" isOpen={(!connected && peerMeta && peerMeta.name)} onClose={this.rejectSession}
              header={<SiteMetaBanner peerMeta={peerMeta} walletAddress={address} />}
              buttonNextOnClick={this.approveSession}
              buttonNextLabel={"Approve"}
              buttonPreviousOnClick={this.rejectSession}
              buttonPreviousLabel={"Reject"}
            >
            </ActionModal>
          )}

          {connected && payload && (
            <ActionModal title="Approve Transaction" isOpen={connected && payload} onClose={this.closeRequest}
              header={<SiteMetaBanner peerMeta={peerMeta} walletAddress={address} />}
              buttonNextOnClick={this.approveRequest}
              buttonNextLabel={"Approve"}
              buttonPreviousOnClick={this.rejectRequest}
              buttonPreviousLabel={"Reject"}
            >
              <RequestDisplay
                payload={payload}
                peerMeta={peerMeta}
                renderPayload={(payload: any) =>
                  getAppConfig().rpcEngine.render(payload)
                }
              />
            </ActionModal>
          )}

          {/* Modals */}
          {scanner && (
            <ActionModal title="WalletConnect" isOpen={scanner} onClose={this.onQRCodeClose}>
              <QRCodeScanner
                onValidate={this.onQRCodeValidate}
                onScan={this.onQRCodeScan}
                onError={this.onQRCodeError}
                onClose={this.onQRCodeClose}
              >
                {getAppConfig().styleOpts.showPasteUri &&
                  <S.Input
                    onChange={this.onURIPaste}
                    placeholder={"Paste wc: uri"}
                    focus
                  />}
              </QRCodeScanner>
            </ActionModal>
          )}

          {/* Footer */}
          <ConnectionBanner
            peerMeta={peerMeta}
            connected={connected}
            killSession={this.killSession}
          />
        </S.Container>
      </React.Fragment>
    );
  }
}

export default App;
