// @ts-nocheck
import * as React from "react";
import { IRequestRenderParams } from "../helpers/types";
import { Heading, Box, Stack, Text } from '@chakra-ui/react'


const Transaction = ({ params }) => {
  return <>
    {params.map(param => {
      return (
        <Box key={param.label}>
          <Heading size='md' fontWeight={"600"}>{param.label}</Heading>
          <Text wordBreak={"break-all"}>{param.value}</Text>
        </Box>
      )
    })}
  </>
}

const Message = ({ params }) => {
  return <>
    {params.map(param => {
      if (param.label != "Message" && param.label != "params") return null
      return (
        <Box key={param.label}>

          <Text wordBreak={"break-all"}>{param.label != "params" ? param.value : JSON.stringify(JSON.parse(param.value), null, 2)}</Text>

        </Box>
      )
    })}
  </>

}

class RequestDisplay extends React.Component<any, any> {
  public render() {
    const {
      payload,
      renderPayload,
    } = this.props;
    const isTransaction = payload.method.includes("send")
    const params: IRequestRenderParams[] = renderPayload(payload);
    console.log("RENDER", "method", payload.method);
    console.log("RENDER", "params", payload.params);
    console.log("RENDER", "formatted", params);
    return (
      <Stack my="20px" spacing='20px'>
        <Heading size='md' >{isTransaction ? "Summary" : "Message"}</Heading>
        {isTransaction ? <Transaction params={params} /> : <Message params={params} />}
      </Stack>
    );
  }
}

export default RequestDisplay;
