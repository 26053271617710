import React, { useEffect } from 'react';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { navigate } from '@reach/router';

export default function Main({
  children,
  header,
  footer,
  bg,
  innerBg = 'white',
}) {
  return (
    <Flex
      minH="100vh"
      align={'center'}
      justify={'center'}
      bg={bg || 'gray.50'}
      flexDirection="column"
    >
      <Box
        mx={'auto'}
        w="100%"
        maxW={{ base: '100%', sm: props => props.modalSize.xs.width }}
        h={{ base: '100vh', sm: props => props.modalSize.xs.height }}
        rounded={{ md: 'lg' }}
        bg={'black'}
        boxShadow={{ sm: 'lg' }}
        overflow="hidden"
        display="flex"
        flexDirection="column"
        position="relative"
      >
        {header}
        <Box flex="1" display="flex" flexDirection="column" overflow={'scroll'}>
          <Box flex="1" minHeight={'100%'}>
            <Box
              display="flex"
              position="relative"
              flexDirection="column"
              flex="1"
              minHeight={{ base: '100%' }}
            >
              {children}
            </Box>
          </Box>
        </Box>
        {footer && (
          <Box
            position={{ base: 'fixed', md: 'absolute' }}
            bottom="20px"
            right="20px"
          >
            {footer}
          </Box>
        )}
      </Box>
    </Flex>
  );
}
