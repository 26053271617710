// @ts-nocheck
import React from "react";
import styled, { css, breakpoints } from "@xstyled/styled-components";
import { Spinner, Text, Flex } from "@chakra-ui/react";

interface ILoadProps {
  isLoading: boolean;
  height?: string;
  children: ReactNode,
  loadingComponent?: ReactElement
  loadingText?: string
}

const Load = ({ isLoading, error, children, height, loadingComponent, loadingText }: ILoadProps) => {
  if (isLoading) {
    if (loadingComponent) {
      return loadingComponent;
    }
    return (
      <LoadingContainer height={height}>
        <Flex direction="column" alignItems="center" justifyContent={"center"}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#747681"
            size="lg"
          />
          {loadingText && <Text mt="15px" color="white">{loadingText}</Text>}
        </Flex>
      </LoadingContainer>
    );
  }

  if (error)
    return (
      <LoadingContainer>
        <div>{error?.message}</div>
      </LoadingContainer>
    );
  return children;
};

export default Load;

const LoadingContainer = styled.div`
  height: ${props => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
`;
