import { Tabs, Box, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { css } from 'styled-components';

const CustomTab = ({ children }) => (
  <Tab
    color={'secondary'}
    borderColor="gray"
    fontWeight="500"
    _selected={{ color: 'white', bg: 'black', borderColor: 'white' }}
    _active={{ color: 'white', bg: 'black', borderColor: 'white' }}
  >
    {children}
  </Tab>
);

const AssetsActivityTabs = ({ assets, activity }) => {
  return (
    <Tabs isFitted defaultIndex={1} colorScheme="gray">
      <TabList>
        <CustomTab>Assets</CustomTab>
        <CustomTab>Activity</CustomTab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Box maxH={'350px'} overflow={'scroll'}>
            {assets}
          </Box>
        </TabPanel>
        <TabPanel>
          <Box maxH={'350px'} overflow={'scroll'}>
            {activity}
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default AssetsActivityTabs;
