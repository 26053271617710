import {
  Avatar,
  Box,
  Text,
  Container,
  Flex,
  Link,
  useColorModeValue,
  Skeleton,
  Stack,
  Image,
} from '@chakra-ui/react';
import { get } from 'lodash';
import Blockies from 'react-blockies';
import { Link as ReachLink } from '@reach/router';
import { css } from 'styled-components';
import { WalletAddress } from '@flex-connect/ui';

const formatUrl = (url = '') => {
  return url.replace(/^https?:\/\//, '');
};

const SiteMetaBanner = ({ username, walletAddress, peerMeta }) => {
  return (
    <Flex
      direction="column"
      width={'full'}
      py={'20px'}
      px={'15px'}
      position={'relative'}
      alignItems="center"
      textAlign={'center'}
    >
      <Stack>
        {peerMeta.icons[0] ? (
          <Image
            mx="auto"
            borderRadius="full"
            boxSize="60px"
            src={peerMeta.icons[0]}
          />
        ) : (
          <Box
            css={css`
              .blockie {
                width: 60px;
                height: 60px;
                border-radius: 100%;
              }
            `}
            mx="auto"
          >
            <Blockies
              seed={peerMeta.url}
              size={10}
              scale={6}
              color="#00B2FF"
              bgColor="#FC1B0D"
              spotColor="##19AB6A"
              className="blockie"
            />
          </Box>
        )}

        <Link as={ReachLink} to={`/${username}`} color={'blue.400'}>
          <Text fontWeight={'medium'} color="white" fontSize={'22px'}>
            {formatUrl(peerMeta.url)}
          </Text>
        </Link>
        <Text fontWeight={'bold'} color="grey" fontSize={14}>
          {!walletAddress ? (
            <Skeleton height="16px" width="100px" mx="auto" />
          ) : (
            <WalletAddress address={walletAddress} />
          )}
        </Text>
      </Stack>
    </Flex>
  );
};

export default SiteMetaBanner;
