import { ReactComponent as Status } from '@/assets/icons/StatusCircle.svg';
import { css } from 'styled-components';

const COLORS = {
  connected: 'blue',
  disconnected: 'red',
  white: 'white',
};
const StatusIndicator = ({ status = 'white' }) => {
  return (
    <Status
      css={css`
        circle {
          fill: ${COLORS[status]};
        }
      `}
    />
  );
};

export default StatusIndicator;
