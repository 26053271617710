import {
  Avatar,
  Box,
  Text,
  Container,
  Flex,
  Link,
  useColorModeValue,
  Skeleton,
} from '@chakra-ui/react';
import { get } from 'lodash';
import Blockies from 'react-blockies';
import { Link as ReachLink } from '@reach/router';
import { css } from 'styled-components';
import { WalletAddress } from '@flex-connect/ui';

const ProfileBanner = ({ username, walletAddress }) => {
  return (
    <Flex
      direction="row"
      width={'full'}
      py={'20px'}
      px={'15px'}
      // mb={10}
      justifyContent={'space-between'}
      position={'relative'}
    >
      <Box
        css={css`
          .blockie {
            width: 60px;
            height: 60px;
            border-radius: 100%;
          }
        `}
        pr={'15px'}
      >
        <Blockies
          seed={walletAddress}
          size={10}
          scale={6}
          color="#00B2FF"
          bgColor="#FC1B0D"
          spotColor="##19AB6A"
          className="blockie"
        />
      </Box>
      <Flex
        direction={'column'}
        textAlign={'left'}
        justifyContent={'center'}
        flex="1"
      >
        <Link as={ReachLink} to={`/${username}`} color={'blue.400'}>
          <Text fontWeight={'medium'} color="white" fontSize={'22px'}>
            {username}
          </Text>
        </Link>
        <Text as="div" fontWeight={'bold'} color="grey" fontSize={14}>
          {!walletAddress ? (
            <Skeleton height="16px" width="100px" />
          ) : (
            <WalletAddress address={walletAddress} />
          )}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ProfileBanner;
