// @ts-nocheck
import React, { useState, useEffect } from "react"
import postRobot from 'post-robot'
import { useApp } from '@/context/AppContext';
import { getAppConfig } from "@/config";

const isPopup = window.opener && window.opener !== window;

export const useFlexPopup = () => {

  const [state, setState] = useApp();

  useEffect(() => {

    const init = async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('sessionId')

      if (sessionId) {
        const flexwc = decodeURI(sessionId)
        window.localStorage.setItem("flexconnect:wc", flexwc)
      }
    }

    init()

  }, [])

  return [];
}

