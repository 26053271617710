// @ts-nocheck
import DirectWebSdk from '@toruslabs/customauth';
import { getAppControllers } from './index';

import { isEmpty } from 'lodash';
export class SignerController {
  privateKey = {};
  sdk = {};

  async init() {
    const { sdk } = getAppControllers();
    this.sdk = sdk;
    if (!this.sdk.auth.tokenParsed) {
      throw Error('Can not initialize due to no login token');
    }
    if (!isEmpty(this.privateKey)) {
      return this.privateKey;
    }
    const torusdirectsdk = new DirectWebSdk({
      baseUrl: `${window?.location.origin}`,
      network: 'testnet',
      enableLogging: true,
    });
    const torusKey = await torusdirectsdk.getTorusKey(
      'flex-connect-development',
      this.sdk.auth.tokenParsed.sub,
      { verifier_id: this.sdk.auth.tokenParsed.sub },
      this.sdk.auth.token
    );

    this.privateKey = torusKey;
    return this.privateKey;
  }
}

let signerController;
export function getSignerController() {
  if (!signerController) {
    signerController = new SignerController();
  }
  return signerController;
}
