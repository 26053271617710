import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Divider,
  Box,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';

const ActionModal = ({
  isOpen,
  onClose,
  header,
  children,
  title,
  footer,
  buttonPreviousOnClick,
  buttonNextOnClick,
  buttonPreviousLabel,
  buttonNextLabel,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="black" h="100vh" w="100vh" maxW="100vh" my="0" mx="0">
        <ModalHeader
          bg="#17181A"
          color="white"
          textAlign={'center'}
          height="60px"
        >
          {title}
          <ModalCloseButton top="18px" width="24px" height={'24px'} />
        </ModalHeader>

        <ModalBody>
          {header && (
            <>
              <Flex height="175px" alignItems="center" justifyContent="center">
                {header}
              </Flex>
              <Divider color="outline.500" borderBottomWidth="2px" />
            </>
          )}
          <Box maxH={header && '230px'} overflow={'scroll'}>
            {children}
          </Box>
        </ModalBody>

        <ModalFooter borderColor="outline.500" borderTopWidth="2px">
          {footer}
          {(buttonPreviousOnClick || buttonNextOnClick) && (
            <ButtonGroup gap="4" flex="1">
              <Button
                size={'lg'}
                // colorScheme="whiteAlpha"
                color="white"
                flex="1"
                variant="secondary"
                onClick={buttonPreviousOnClick}
              >
                {buttonPreviousLabel}
              </Button>
              <Button
                size={'lg'}
                bg="green"
                flex="1"
                variant="solid"
                onClick={buttonNextOnClick}
              >
                {buttonNextLabel}
              </Button>
            </ButtonGroup>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ActionModal;
