import * as React from "react";
import { createRoot } from 'react-dom/client';
import { createGlobalStyle } from "styled-components";

import { globalStyle } from "./styles";

const container = document.getElementById('root');
const root = createRoot(container!);

import App from "./App";


const GlobalStyle = createGlobalStyle`
  /* ${globalStyle} */
`;

root.render(<>
  <GlobalStyle />
  <App />
</>);
