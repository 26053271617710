import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "ABCWhyteInktrap, sans-serif",
    body: "ABCWhyte, sans-serif"
  },
  colors: {
    black: "#000000",
    green: "#19AB6A",
    grey: "#17181A",
    red: "#FC1B0D",
    lightBlue: "#00B2FF",
    secondary: "#8F9095",
    dark: {
      500: "#17181A"
    },
    outline: {
      400: "#8F9095",
      500: "#494948"
    }
  },
  modalSize: {
    xs: {
      width: "360px",
      height: "600px"
    }
  },
  components: {
    // Text: {
    //   baseStyle: {
    //     color: "red"
    //   }
    // },
    Button: {
      variants: {
        base: {
          backgroundColor: "grey",
          _pressed: {
            backgroundColor: "red"
          },
          borderRadius: "100%"
        }
      },
      defaultProps: {
        variant: "base"
      }
    }
  }
});

export default theme;
