// @ts-nocheck
import FlexConnect from '@crowdform/flex-connect-sdk';

let sdk = new FlexConnect('flex-wallet', {});

sdk.init({
  disableAuthInit: true,
});

export const authClient = sdk.authClient();

export function getFlexController() {
  return sdk;
}
