// @ts-nocheck
import * as React from "react";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { ChakraProvider } from '@chakra-ui/react'
import { getDefaultProvider } from 'ethers'

import Authentication from "@/screens/Authentication";
import WalletContainer from "@/screens/WalletContainer";
import { Mainnet, Rinkeby, DAppProvider, useEtherBalance, useEthers, Config, Polygon, Mumbai } from '@usedapp/core'

import { getAppControllers } from "@/controllers";
import { UserContextProvider } from "./context/UserContext";
import { AppContextProvider } from "./context/AppContext";

import { SUPPORTED_CHAINS } from '@/constants/chains'

import { authClient } from '@/controllers/flex'
import theme from './theme'

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});


const API_KEY = process.env.REACT_APP_INFURA_PROJECT_ID;

const apiKey = (rpc_url: string) => rpc_url.replace('%API_KEY%', API_KEY);

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  fastMulticallEncoding: true,
  noMetamaskDeactivate: true,
  readOnlyUrls: {
    [Mainnet.chainId]: apiKey("https://mainnet.infura.io/v3/%API_KEY%"),
    [Rinkeby.chainId]: apiKey("https://rinkeby.infura.io/v3/%API_KEY%"),
    [Polygon.chainId]: apiKey("https://polygon-mainnet.infura.io/v3/%API_KEY%"),
    [Mumbai.chainId]: apiKey("https://polygon-mumbai.infura.io/v3/%API_KEY%"),
  },
}

function App() {
  return (
    <ReactKeycloakProvider authClient={authClient} initOptions={{
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
    }}>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AppContextProvider>
            <UserContextProvider>
              <DAppProvider config={config}>
                <Authentication>
                  <WalletContainer />
                </Authentication>
              </DAppProvider>
            </UserContextProvider>
          </AppContextProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
