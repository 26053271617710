import '@flex-connect/ui/assets/font.css';
import { theme } from '@flex-connect/ui';

// import all fonts and @font-family
// @ts-ignore

const darkTheme = {
  ...theme,
  styles: {
    global: {
      'html, body': {
        color: 'white',
      },
    },
  },
  breakpoints: {
    ...theme.breakpoints,
    _: 0,
  },
  components: {
    ...theme.components,
    Text: {
      baseStyle: {
        color: 'white',
        fontSize: '15px',
      },
    },
    Heading: {
      sizes: {
        md: {
          fontWeight: '500',
          fontSize: '15px',
        },
      },
    },
    Link: {
      baseStyle: {
        color: 'white',
        fontSize: '15px',
      },
    },
    Button: {
      baseStyle: {
        borderRadius: '100px',
      },

      variants: {
        secondary: {
          border: '2px',
          borderColor: 'outline.500',
        },
      },
      sizes: {
        lg: {
          h: '49px',
          fontWeight: '700',
        },
      },
    },
  },
};

export default darkTheme;
