import { Flex, Text, Box } from '@chakra-ui/react';
import { css } from 'styled-components';

import { ReactComponent as SignRequest } from '@/assets/icons/SignRequest.svg';
import { ReactComponent as TransactionRequest } from '@/assets/icons/TransactionRequest.svg';
import { ReactComponent as MoreThan } from '@/assets/icons/MoreThan.svg';

import { requestMethod } from '@/helpers/utilities';

const ActivityRequest = ({ peerMeta, request, openRequest }) => {
  return (
    <Flex
      cursor={'pointer'}
      key={request.id}
      alignItems="center"
      onClick={() => openRequest(request)}
    >
      <Box pr="15px">
        {request.method.includes('send') ? (
          <TransactionRequest />
        ) : (
          <SignRequest />
        )}
      </Box>
      <Box flex="1">
        <Text fontSize="17px" fontWeight={'500'}>
          {peerMeta.name}
        </Text>
        <Text fontSize="14px" color="outline.400">
          {requestMethod(request.method)}
        </Text>
      </Box>
      <MoreThan />
    </Flex>
  );
};

export default ActivityRequest;
