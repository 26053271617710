import {
  Avatar,
  Box,
  Text,
  Container,
  Flex,
  Link,
  useColorModeValue,
  Skeleton,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';

import { useKeycloak } from '@react-keycloak/web';

import userSVG from '@/assets/icons/User.svg';
import qrSVG from '@/assets/icons/QR.svg';

import { get } from 'lodash';
import { Link as ReachLink } from '@reach/router';
import styled, { css } from 'styled-components';
import { WalletAddress } from '@flex-connect/ui';

const HeaderBanner = ({ children, connected, toggleScanner }) => {
  const { keycloak } = useKeycloak();
  return (
    <Flex
      direction="row"
      width={'full'}
      py={'20px'}
      px={'15px'}
      justifyContent={'space-between'}
      position={'relative'}
    >
      <Box flex="1" mr="60px">
        {children}
      </Box>
      <Flex>
        {!connected && (
          <IconButton aria-label="QR Code" onClick={toggleScanner}>
            <Icon icon={qrSVG} />
          </IconButton>
        )}
        <Menu width="auto">
          <MenuButton
            as={IconButton}
            icon={<Icon icon={userSVG} />}
            aria-label="Profile"
            mx="10px"
          />
          <MenuList
            bg="grey"
            minW={'auto'}
            width="auto"
            py="0"
            borderColor={'transparent'}
            borderRadius="100px"
          >
            <MenuItem
              bg="grey"
              color="red"
              _hover={{ bg: 'black' }}
              _selected={{ bg: 'black' }}
              _selection={{ bg: 'black' }}
              width="auto"
              borderRadius="100px"
              onClick={() => keycloak.logout()}
            >
              Sign out
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

const Icon = styled.div`
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  display: block;
`;

export default HeaderBanner;
